import * as React from "react";

import ContactForm from "../components/Contact/ContactForm";
import Layout from "../components/Base/Layout";
import { SvgBackground } from '../components/Base/SvgLogo'

export default function LogIn() {
  
  return (
    <Layout>
      <SvgBackground position="absolute" bottom="0" right="0" paddingBottom="4" ></SvgBackground>
      <ContactForm />
    </Layout>
  );
}
