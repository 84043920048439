import { Button, Container, FormControl, FormLabel, Heading, Input, Stack, Text, Textarea, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react";
import { ValidationError, useForm } from '@formspree/react';

import { IconMail } from '@tabler/icons';
import { Logo } from "../Logo/Logo";
import validator from 'validator'

export default function ContactForm() {

  var toast = useToast()

  const [state, handleSubmit] = useForm("mnqrjvnq");

  if (state.succeeded) {
    toast({
      status: 'success',
      title: "Message was sent!"
     })
  }


  //initial values + setting new ones by typing in input
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    company: "",
    message: ""
  })
  //error fields
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

   const handleChange = (input:any) => (e:any)  => {
     const value = e.currentTarget.value;
     
     setFormValues(prevState => ({
       ...prevState,
      [input]: value
  }));
     
   };

  //form validation
  const handleFormData = (event: React.FormEvent<HTMLFormElement>) => {

    // const [state, handleSubmit] = useForm("mnqrjvnq");
    event.preventDefault();

    setFormErrors(validate(formValues));
    setIsSubmit(true) 

  }

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // useForm("mnqrjvnq")
    }
  }, [formErrors]);

  const validate = (values: any) => {
    
    type errorType = {
      fullName?: string,
      message?: string
    }
    const errors: errorType ={}

    if (! validator.isLength(values.fullName, {min: 2, max: 100})) {
      errors.fullName = "Full name is too short"
    }

    if (!validator.isLength(values.message, { min: 10, max: 1000 })) {
      errors.message = "Message is too short"
    }

    return errors
  }

  return (
     <Container maxW="448px" py={{ base: '12', md: '16' }} px={{base: "16px", md: "0px"}} position="relative">
      <Stack spacing="8" w={{base: "100%", md: "448px"}}>
        <Stack spacing="6" alignItems="center">
          <Logo />
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center" mt="24px !important">
            <Heading size="xl">
              Contact Us
            </Heading>
            <Text color="gray.600">Send us a message using the form bellow, <br></br> or call our customer service +1 855 564-1492
          </Text>
          </Stack>
        </Stack>
        <Stack spacing="6" background="white" padding="40px" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);">
          <form onSubmit={handleSubmit}>
        <Stack spacing="5">
            <FormControl>
              <FormLabel htmlFor="email">Full Name</FormLabel>
                <Input id="fullName" type="text" name="fullName" required defaultValue={formValues.fullName} onChange={handleChange("fullName")} />
                <ValidationError  prefix="fullName"  field="fullName" errors={state.errors}/>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email address</FormLabel>
                <Input id="email" type="email" name="email" required defaultValue={formValues.email} onChange={handleChange("email")} />
               <ValidationError  prefix="Email"  field="email" errors={state.errors}/>
          </FormControl>
         <FormControl>
              <FormLabel htmlFor="email">Company (optional)</FormLabel>
              <Input id="email" type="text" name="Company" defaultValue={formValues.company} onChange={handleChange("company")} />
          </FormControl> 
        <FormControl>
              <FormLabel htmlFor="email">Message</FormLabel>
                <Textarea id="Message" name="Message" required defaultValue={formValues.message} onChange={handleChange("message")} />
                <ValidationError  prefix="Message"  field="Message" errors={state.errors}/>
          </FormControl>
        </Stack>
        <Stack paddingTop="6">
          <Button disabled={state.submitting} type="submit" variant="solid" size="lg" colorScheme="blue" rightIcon={<IconMail width="18px" height="18px" />} >Send Message</Button>
        </Stack>
            </form>
      </Stack>
    </Stack>
  </Container>
  )
}
